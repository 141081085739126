<script setup lang="ts">
// components
import CiSkeletonItem from '@/components/skeleton/CiSkeletonItem.vue';
import CiSkeletonPromoSlider from '@/components/skeleton/CiSkeletonPromoSlider.vue';
import CiSlotContent from '@/components/slots/CiSlotContent.vue';

definePageMeta({
  layout: 'home',
});

/********************
 * COMPOSITIONS     *
 ********************/
const homeStore = useHomeStore();
const loadingStore = useLoadingStore();
const localePath = useLocalePath();
const magazineService = useMagazineService();
const runtimeConfig = useRuntimeConfig();
const viewport = useViewport();
const { $gettext } = useGettext();
const { data: cachedHome } = useNuxtData('home');
const { dynamicHeadline } = useDynamicHeadline();
const { locale } = useI18n();

/********************
 * INITIALIZATION    *
 ********************/
const { data, pending } = await useLazyAsyncData('home', () => getAllHomeData());

async function getAllHomeData() {
  if (cachedHome.value) {
    return {
      ...cachedHome.value,
      hero: homeStore.heros?.[Math.floor(Math.random() * homeStore.heros?.length)],
    };
  }

  const [homeResponse, magazineResponse] = await Promise.all([
    homeStore.getHomeData({ lang: locale.value }),
    locale.value === 'de' || locale.value === 'en' ? magazineService.getLatest(locale.value) : [],
  ]);

  return {
    hero: homeResponse.heros[Math.floor(Math.random() * homeResponse.heros.length)],
    magazineResponse,
  };
}

/********************
 * REFS & VARS       *
 ********************/
const i18nPromoHeadline = $gettext('<strong>Selected</strong> campsites');

const jsonLd = computed(() => {
  const searchUrl = localePath({ name: RBN_SEARCH });
  return {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    'name': 'camping.info',
    'url': `${runtimeConfig.public.baseUrl}/${locale.value}`,
    'potentialAction': {
      '@type': 'SearchAction',
      'target': `${runtimeConfig.public.baseUrl}${searchUrl}?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };
});

if (jsonLd.value) {
  useHead({
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify(jsonLd.value),
      },
    ],
  });
}
</script>

<template>
  <div class="mb-12">
    <CiCampsiteHero
      :headline="dynamicHeadline"
      :hero="data?.hero"
    />

    <div
      class="bg-[center_3rem] bg-no-repeat md:bg-[url('/assets/img/confetti-tablet@3x.png')] md:bg-[length:768px_283px] lg:bg-[url('/assets/img/confetti-desktop@3x.png')] lg:bg-[length:1400px_774px]"
    >
      <!-- search input -->
      <div
        class="relative z-20 mx-auto my-0 w-full max-w-full -translate-y-1/2 px-[15px] sm:max-w-[540px] md:max-w-[940px]"
      >
        <CiSearch :is-mobile="viewport.isLessThan('md')" />
      </div>

      <div class="-mt-4 overflow-hidden">
        <!-- promo slides -->
        <CiPromoSlider
          v-if="!pending"
          :campsites="homeStore.prominentCampsites"
        >
          <!-- headline -->
          <template #headline>
            <h2
              class="m-0 mb-4 text-center"
              v-html="i18nPromoHeadline"
            />
          </template>

          <!-- slides -->
          <template #slide="{ campsite, index }">
            <NuxtLazyHydrate when-visible>
              <CiPromoTile
                :campsite="campsite"
                :snowplow-context="{
                  bannerId: 'SelectedCampsitesSlider',
                  campaignId: 'prominent',
                  zoneId: 'belowSearch',
                  position: index + 1,
                }"
              />
            </NuxtLazyHydrate>
          </template>
        </CiPromoSlider>
        <CiSkeletonPromoSlider
          v-else
          class="my-6"
        />

        <!-- content service -->
        <CiSlotContent class="view-home__content z-10" />
        <div
          v-if="loadingStore.isLoading('getContent')"
          class="container flex flex-col gap-5"
        >
          <CiSkeletonItem class="h-[300px]" />
          <CiSkeletonItem class="h-[300px]" />
          <CiSkeletonItem class="h-[300px]" />
        </div>

        <!-- magazine-posts -->
        <NuxtLazyHydrate when-visible>
          <CiMagazinePosts
            :loading="pending"
            :posts="data?.magazineResponse"
          />
        </NuxtLazyHydrate>
      </div>
    </div>
  </div>
</template>
