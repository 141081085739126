import { defineStore, acceptHMRUpdate } from 'pinia';

// Stores
import { useLoadingStore } from '@/stores/loading';

export const useHomeStore = defineStore('home', () => {
  /********************
   * COMPOSITIONS      *
   ********************/
  const homeService = useHomeService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  /********************
   * REFS & VARS       *
   ********************/
  const heros = shallowRef<[] | null>(null);
  const prominentCampsites = shallowRef([]);

  /********************
   * FUNCTIONS         *
   ********************/
  async function getHomeData({ lang }: { lang: string }) {
    if (prominentCampsites.value?.length) {
      return {
        heros: heros.value,
        prominent_campsites: prominentCampsites.value,
      };
    }

    setStatusLoading('getHomeData');

    try {
      const response = await homeService.getHomeData(lang);

      if (response.heros?.length) {
        heros.value = response.heros;
      }

      if (response.prominent_campsites) {
        prominentCampsites.value = response.prominent_campsites;
      }

      setStatusSuccess('getHomeData');
      return response;
    } catch (error) {
      setStatusError({ name: 'getHomeData', error });
      throw error;
    }
  }

  return {
    getHomeData,
    heros,
    prominentCampsites,
  };
});

export type HomeStore = ReturnType<typeof useHomeStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHomeStore, import.meta.hot));
}
